import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Formik, FormikHelpers } from 'formik';

import { currentClientAtom } from '../../recoil/atoms/Clients';
import ClientManage from '../../components/clients/ClientManage';
import Button from '../../components/shared/form-control/Button';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import { Roles } from '../../models/Role';
import { useTranslation } from 'react-i18next';
import useSchemas from '../../schema';
import ClientService from '../../services/ClientService';
import { ClientMaintainDto } from '../../models/Client';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import { BreadCrumbBackButton } from '../../components/shared/breadcumb/BreadCrumbBackButton';
import usePermissions from '../../hooks/permissions/usePermissions';
import { systemDefaultLanguageCode } from '../../types/Languages';
import { usePbkAuth } from '../../contexts/PbkAuthContext';

const INITIAL_VALUES: ClientMaintainDto = {
  client: {
    name: '',
    industry: '',
    size: '',
    accountNumber: '',
    tenantId: null,
    language: systemDefaultLanguageCode,
  },
};

const AddClient: FC = () => {
  const navigate = useNavigate();
  const setCurrentClient = useSetRecoilState(currentClientAtom);
  const schemas = useSchemas();
  const { t } = useTranslation(['client-manage', 'client-list', 'form']);
  const hasPermission = usePermissions();
  const { refreshToken } = usePbkAuth();

  useEffect(() => {
    setCurrentClient(null);
  }, [setCurrentClient]);

  const createClient = (values: ClientMaintainDto, { setSubmitting }: FormikHelpers<ClientMaintainDto>) => {
    const { client } = values;

    ClientService.createClient(client)
      .then((res) => {
        return res.data.id;
      })
      .then((clientId) => {
        refreshToken().then(() => {
          navigate(`/clients/${clientId}`);
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="bg-background-1 h-full p-8">
      <Formik
        enableReinitialize
        validateOnChange
        validationSchema={schemas.client.maintainClient}
        onSubmit={createClient}
        initialValues={INITIAL_VALUES}
      >
        {(props) => {
          const { isValid, isInitialValid, handleSubmit, dirty, isSubmitting } = props;

          const disableCreate = !hasPermission(Roles.ConsultantManager) || !dirty || !(isValid || isInitialValid) || isSubmitting;
          const disabledTitle = !dirty ? t('form:titles.no-changes') : t('form:titles.resolve-errors');

          const handleCreateClient = (): void => {
            handleSubmit();
          };

          return (
            <form>
              <TopNavPortal>
                <Heading size={HeadingSize.H3} textColor="text-color-2">
                  {t('client-list:heading')}
                </Heading>
              </TopNavPortal>

              <div className="flex w-full items-start justify-between">
                <div>
                  <div className="flex items-center gap-4">
                    <BreadCrumbBackButton onClick={() => navigate('/clients/')}></BreadCrumbBackButton>
                    <Heading size={HeadingSize.H3} textColor="text-color-2">
                      {t('client-manage:create.heading')}
                    </Heading>
                  </div>
                  <Heading size={HeadingSize.H4} textColor="text-color-2" className="pt-4">
                    {t('client-manage:details.heading')}
                  </Heading>
                </div>

                <Button
                  data-cy="create-client"
                  onClick={handleCreateClient}
                  title={disableCreate ? disabledTitle : t('client-manage:titles.create-client')}
                >
                  {t('client-manage:buttons.create')}
                </Button>
              </div>

              <ClientManage formik={props} />
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddClient;
